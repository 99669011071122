import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import exitosa from "@assets/images/renovacion/exitosa.png";


export const RenewPolicy: React.FC = () => {

    return (
        <Grid container spacing={0} columns={12} sx={{alignItems: 'center', justifyContent: 'center'}}>
            <Grid item xs={12} md={6} lg={6} xl={5}>
                <Box sx={{
                    display: 'flex', width: '100%', height: '700px',
                    flexDirection: 'column',
                }}>
                    <Box
                        component={'img'}
                        src={exitosa}
                        sx={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                        }}
                        alt="exitosa"
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={5}>
                <Box sx={{
                    display: 'flex', width: '100%', height: {xs:'100%',sm:'700px'},
                    flexDirection: 'column',
                    justifyContent:'center',
                    gap:1,
                    alignItems:'center',
                    backgroundColor: "#0043A5"
                }}>
                    <Box component={'span'} sx={{
                        padding: {xs: '40px 38px  0px  38px', sm: '40px 58px  0px  58px'},
                        width: '80%',
                        color: "var(--White, #FFF)",
                        fontSize: "40px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "114%",
                    }}>
                        {"Tu renovación se realizó con éxito"}
                    </Box>


                    <Box component={'span'} sx={{
                        padding: {xs: '40px 38px  0px  38px', sm: '40px 58px  0px  58px'},
                        alignItems:'center',
                        width: '80%',
                        color: "var(--White, #FFF)",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "150%"
                    }}
                    >
                        {"Tu póliza de AutoProtección Coppel fue renovada con éxito, en un lapso no mayor a 72 horas la recibirás por correo. "}
                        {/*{"Tu póliza de AutoProtección Coppel fue renovada con éxito, en un lapso no mayor a 72 horas hábiles nos pondremos en contacto contigo, agradecemos tu confianza en nuestros servicios y queremos seguir siendo la opción de seguro favorita para tus vehículos y los de tus familiares."}*/}
                    </Box>
                    <Box component={'span'} sx={{
                        padding: {xs: '40px 38px  0px  38px', sm: '40px 58px  0px  58px'},
                        width: '80%',
                        color: "var(--White, #FFF)",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "150%"
                    }}
                    >
                        {"Agradecemos tu confianza en nuestros servicios y queremos seguir siendo la opción de seguro favorita para tus vehículos y los de tus familiares."}
                        {/*{"Tu póliza de AutoProtección Coppel fue renovada con éxito, en un lapso no mayor a 72 horas hábiles nos pondremos en contacto contigo, agradecemos tu confianza en nuestros servicios y queremos seguir siendo la opción de seguro favorita para tus vehículos y los de tus familiares."}*/}
                    </Box>
                    <Box sx={{
                        flex: {xs: 0, sm: .2, md: .1,lg: 0},
                        padding: {xs: '30px 38px 80px 38px', sm: '40px 58px 60px 58px',md: '0px 58px 60px 58px',lg: '40px 58px 40px 58px'},
                    }}>
                        <Typography
                            sx={{
                                width: '100%',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: `<div style="width: 100%"><span style="color: white; font-size: 14px;  font-weight: 400; line-height: 21px; word-wrap: break-word">Si tienes alguna duda, comunícate por teléfono al: </span><span style="color: white; font-size: 14px;  font-weight: 700; line-height: 21px; word-wrap: break-word">55 8979 0980</span><span style="color: white; font-size: 14px;  font-weight: 400; line-height: 21px; word-wrap: break-word"> o si lo prefieres, escríbenos al correo:  </span><span style="color: white; font-size: 14px;  font-weight: 700; line-height: 21px; word-wrap: break-word">ren_autoproteccion@inter.mx</span><span style="color: white; font-size: 14px; font-weight: 400; line-height: 21px; word-wrap: break-word">. </span></div>`
                            }}/>
                    </Box>



                </Box>

            </Grid>

        </Grid>
    )
}
