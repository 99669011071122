import { Home } from '@pages/Home';
import { Mantenimiento } from '@pages/Mantenimiento';
import { Plans } from '@pages/Plans';
import { Result } from '@pages/Result';
import { Benefits } from '@pages/Benefits';
import { Hiring } from '@pages/Hiring';
import { Confirm } from '@pages/Confirm';
import { Route } from '@models/navigation/route';
import { Details } from '@pages/Details';
import { Payment } from '@pages/Payment';
import { WelcomeToInter } from '@pages/WelcomeToInter';
import { Options } from '@pages/Options';
import { InsurerDetails } from '@pages/InsurerDetails';
import { Comparation } from '@pages/Comparation';
import { HomeAlt } from '@pages/HomeAlt';
import { WelcomeToInterPaymentFail } from '@pages/WelcomeToInterPaymentFail';
import { WelcomeToInterFailIntern } from '@pages/WelcomeToInterFailIntern';
import { ZurichPayment } from '@pages/ZurichPayment';
import { WelcomeToInterFailInterLast } from '@pages/WelcomeToInterFailInterLast';
import {Renovations} from "@pages/Renovations";

export const routes: Route[] = [
  {
    exact: true,
    path: '/',
    component: Home,
  },
  { path: '/benefits', component: Benefits },
  {
    path: '/hiring',
    component: Hiring,
  },
  {
    path: '/confirm',
    component: Confirm,
  },
  { path: '/result', component: Result },
  { path: '/plans', component: Plans },
  { path: '/details', component: Details },
  { path: '/payment', component: Payment },
  { path: '/welcome', component: WelcomeToInter },
  { path: '/welcome2', component: WelcomeToInterPaymentFail },
  { path: '/welcome3', component: WelcomeToInterFailIntern },
  { path: '/welcome3-last', component: WelcomeToInterFailInterLast },
  { path: '/options', component: Options },
  { path: '/insurer-details', component: InsurerDetails },
  { path: '/comparation', component: Comparation },
  { path: '/home-alt', component: HomeAlt },
  { path: '/quotation', exact: true, component: Home },
  { path: '/zurichPayment', component: ZurichPayment },
  {
    path: '/renovaciones/:status',
    component: Renovations,
  },
  {
    path: '/:id',
    component: Home,
  },
];
