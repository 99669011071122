import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  Container,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  useScrollTrigger,
  Grid,
} from '@mui/material';

import { Header } from '@navigationMenu/Header';
import { RightCard } from '@screens/hiring/confirm/RightCard';
import { LeftCard } from '@screens/hiring/confirm/LeftCard';
import { ModalPay } from '@modals/ModalPay';
import { ZustandContext } from '@store/modalStore';
import { DigicertField } from '@navigationMenu/DigicertField';
import { useChangePrice } from '@hooks/useChangePrice';
import { PolicyNumber } from '@screens/hiring/confirm/policyNumber/PolicyNumber';

import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { usePolicyStore } from '@store/policyStore';
import { useHistory } from 'react-router-dom';
import { CustomButton } from '@buttons/CustomButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Toats } from '@components/alerts/Toats';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#f5f5f5',
    paddingBottom: '100px',
  },
}));

export const Confirm: React.FC = () => {
  const classes = useStyles();
  const { breakpoints, palette } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const { show, hide } = React.useContext(ZustandContext);
  const history = useHistory();

  const { policy } = usePolicyStore();
  const { car } = useCarStore();

  const trigger = useScrollTrigger({
    threshold: 970,
    disableHysteresis: true,
  });

  const { driver } = useDriverStore();
  const [showToast, setShowToast] = React.useState(false);

  if (
    policy.Niv === '' ||
    policy.contrator?.firstName === '' ||
    policy.driver.firstName === '' ||
    policy.street === '' ||
    car.assembler === '' ||
    driver.codigoPostal === ''
  ) {
    history.push('/hiring');
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useChangePrice();

  return (
    <>
      <div className={classes.root}> 
        <Container maxWidth={'lg'}>
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              marginTop: 100,
              position: 'relative',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "25px"
            }}
          >
            <CustomButton
              text={'Regresar'}
              onClick={() => history.push('/hiring')}
              size={isDesktop ? 'medium' : 'small'}
              variant={'text'}
              startIcon={<KeyboardArrowLeftIcon />}
              style={{
                color: palette.primary.main,
                fontSize: isMobile ? 14 : 16,
                textTransform: 'none',
                fontWeight: 'normal',
                textDecoration: 'underline',
              }}
            />
            <div style={{marginRight: isTablet ? "0px" : "-4.5%"}}>
              <Button
                onClick={() => {
                  history.push('/home');
                }}
                variant="outlined"
                style={{
                  textTransform: 'none',
                  marginRight: '15px',
                  backgroundColor: '#FFFFFF',
                  padding: '4px 19px',
                  borderRadius: '10px',
                }}
              >
                Nueva cotización
              </Button>
            </div>
          </Box>
            <Box
              display={ isMobile ? "block" : 'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Header
                title="Confirma tu compra"
                subtitle="Verifica que todos tus datos sean correctos"
                number="4"
              />
              <div style={{marginRight: isTablet ? "0px" : "-3.5%", marginTop: isMobile ? "10px" : "0px"}}>
                <PolicyNumber />
              </div>
            </Box>
          {/* </Box> */}
          <Box
            sx={{ flexGrow: 1 }}
            padding={isMobile ? 0 : 2}
            style={{
              position: 'relative',
              minHeight: '70vh',
              marginTop: 10,
            }}
          >
            <Grid
              container
              spacing={isTablet ? 0 : 10}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'start'}
              justifyContent={'space-between'}
            >
              <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '21px' }}>
                {policy.Niv !== '' &&
                  policy.contrator?.firstName !== '' &&
                  policy.driver.firstName !== '' &&
                  policy.street !== '' &&
                  car.assembler !== '' &&
                  driver.codigoPostal !== '' && <LeftCard />}
              </Grid>
              <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '21px' }}>
                {policy.Niv !== '' &&
                  policy.contrator?.firstName !== '' &&
                  policy.driver.firstName !== '' &&
                  policy.street !== '' &&
                  car.assembler !== '' &&
                  driver.codigoPostal !== '' && <RightCard setShowToast={setShowToast} />}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      {isTablet ? <DigicertField /> : null}
    </>
  );
};
